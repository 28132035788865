import * as React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { Grid, List, Container, Feed, Segment, Comment } from "semantic-ui-react";
import { MarkdownRemarkConnection, ImageSharp } from "../graphql-types";
import BlogTitle from "../components/BlogTitle";
import Card from "../components/Card";
import Header from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import TagsCard from "../components/TagsCard/TagsCard";
import BlogPagination from "../components/BlogPagination/BlogPagination";
import { get } from "lodash";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";
import Data from "../../content/talks.json";

interface TalkProps extends LayoutProps {
  data: {
    tags: MarkdownRemarkConnection;
    talks: MarkdownRemarkConnection;
  };
  pageContext: {
    tag?: string; // only set into `templates/tags-pages.tsx`
  };
}

const TalkPage = (props: TalkProps) => {
  const tags = props.data.tags.group;
  const talks = props.data.talks.edges;
  const { pathname } = props.location;
  const pageCount = Math.ceil(props.data.talks.totalCount / 10);

  // TODO export posts in a proper component
  const Talks = (
    <Container>
      {talks.map(({ node }: { node: MarkdownRemark }) => {
        const { frontmatter, timeToRead, fields: { slug }, excerpt } = node;
        console.log(frontmatter);

        const description = (
          <Card.Description>
            {excerpt}
            <br />
            <Link to={slug}>Read more…</Link>
          </Card.Description>
        );

        return (
          <Card
            key={slug}
            title={frontmatter.title}
            description={{
              excerpt: frontmatter.description,
              slug,
            }}
          />
        );
      })}
    </Container>
  );

  return (
    <React.Fragment>
      {/* Title */}
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      <Header title={Data.title} strapline={Data.strapline} />

      {/* Content */}
      <section className="l-section">
        <div className="l-blog-list-container">
          <div style={{ maxWidth: 600 }}>
            {Talks}
            <Segment vertical textAlign="center">
              <BlogPagination Link={Link} pathname={pathname} pageCount={pageCount} />
            </Segment>
          </div>
          <div>
            <TagsCard Link={Link} tags={tags} tag={props.pageContext.tag} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withLayout(TalkPage);

export const pageQuery = graphql`
query PageTalk {
  # Get tags
  tags: allMdx(filter: {frontmatter: {draft: {ne: true}}}) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }

  # Get talks
  talks: allMdx(
    sort: { order: DESC, fields: [frontmatter___updatedDate] },
    filter: {
      frontmatter: { draft: { ne: true } },
      fileAbsolutePath: { regex: "/talks/" }
    },
    limit: 10
  ) {
    totalCount
    edges {
      node {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          title
          description
          strapline
          updatedDate(formatString: "DD MMMM, YYYY")
        }
      }
    }
  }
}
`;
