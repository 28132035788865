import * as React from "react";
import { GatsbyLinkProps } from "gatsby-link";
import { Card, List } from "semantic-ui-react";
import { MarkdownRemarkGroupConnection } from "../../graphql-types";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface TagsCardProps extends React.HTMLProps<HTMLDivElement> {
  tags: MarkdownRemarkGroupConnection[];
  Link: React.ComponentClass<GatsbyLinkProps<any>>;
  tag?: string;
}

const component = new Bem("c-tags-card");

export default (props: TagsCardProps) => {
  return (
    <div className={component.with()}>
      <div className={component.with("header")}>
        <h3>Tags</h3>
      </div>
      <div className={component.with("container")}>
        <ul className={component.with("list")}>
          {props.tags.map((tag) => {
            const isActive = tag.fieldValue === props.tag;
            const activeStyle = {
              fontWeight: "700",
            };
            const tagLink = isActive ? `/blog` : `/blog/tags/${tag.fieldValue}/`;
            return (
              <li key={tag.fieldValue}>
                <props.Link to={tagLink}>
                  {tag.fieldValue} ({tag.totalCount})
                </props.Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
